angular.module('munchableApp')
  .controller('participantCreator.slide1.controller', [
    '$scope',
    '$timeout',
    'tagsService',
    'setsService',
    'participantService',
    '$filter',

    function ($scope, $timeout, tagsService, setsService, participantService, $filter) {
      $scope.currentParticipant = participantService.participant;

      $scope.setsCollection = null;
      $scope.tags = [];

      $scope.addSet = addSet;
      $scope.closePopup = closePopup;

      $scope.currTags = [];
      $scope.currSet = null;

      $scope.popupOpen = false;

      $scope.setBlockSlide = $scope.$parent.setBlockSlide;
      $scope.setAllowSlide = $scope.$parent.setAllowSlide;


      function init() {
        //initially load all tags for the view
        setsService.getSetByCategory('preference').then(function (result) {
          $scope.setsCollection = result;
          tagsService.getAllTags().then(function (res) {
            $scope.tags = res;
            fillSetsWithTags();

            //dumb solution to set steps height when first slide is init
            $timeout(function () {
              $scope.$parent.setStepsHeight(true);

            }, 700);
          });

        }, function (err) {
          $scope.error = err;
        });

      }

      function fillSetsWithTags() {
        var l = $scope.setsCollection.length;
        for (var i = 0; i < l; i++) {
          var tagL = $scope.setsCollection[i].tags.length;

          for (var j = 0; j < tagL; j++) {
            (function (i, j) {
              var currObj = {},
                currId = $scope.setsCollection[i].tags[j];

              tagsService.getTagById(currId).then(function (result) {
                currObj = result;
                $scope.setsCollection[i].tags[j] = currObj;
              }, function (err) {
                $scope.error = err;
              });
            })(i, j);
          }
        }
      }

      function addSet(setId) {
        $scope.currSet = $filter('filter')($scope.setsCollection, { id: setId })[0];
        $scope.popupOpen = true;
        $scope.setBlockSlide(null, true);
      }

      function closePopup() {
        $scope.setAllowSlide();
      }

      init();
    }
  ]);


