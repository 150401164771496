angular
  .module('munchableApp')
  .directive('tagFilter', [
    '$timeout',
    'participantService',
    function ($timeout, participantService) {
      function controller($scope) {
        var timer = null;

        $scope.addTag = addTag;
        $scope.closeDropdown = closeDropdown;

        $scope.customTagAdded = false;
        $scope.addCustomTag = addCustomTag;

        $scope.filterText = null;
        $scope.$on('$destroy', kill);


        function kill() {
          clearTimeout(timer)
        }

        function addTag(tag) {
          participantService.addTagToParticipant(tag);
          tag.checked = true;

          timer = $timeout(function () {
            $scope.customTagAdded = false;
            closeDropdown();
          }, 300);
        }

        function addCustomTag(customTagLabel) {
          participantService.addCustomTagToParticipant(customTagLabel);
          $scope.customTagAdded = true;

          timer = $timeout(function () {
            $scope.customTagAdded = false;
          }, 500);
        }


        function closeDropdown() {
          $scope.filterText = null;
        }
      }

      return {
        scope: {
          allTags: '='
        },
        restrict: 'E',
        controller,
        replace: true,
        template: require('./tagFilter.tpl.html')
      }
    }
  ])
  ;


