angular.module('munchableApp')
  .factory('participantService', [
    '$http',
    '$routeParams',
    'api',

    function ($http, $routeParams, api) {
      var participant = {
        name: '',
        termsOfUse: null,
        setsCollection: [],
        tagsCollection: [],
        customTagsCollection: []
      },
        url = api,
        eventId = $routeParams.eventID;

      function addParticipantToEvent() {
        var participantToSubmit = {};

        deleteCheckedProp();

        participantToSubmit.name = participant.name;
        participantToSubmit.categories = participant.tagsCollection;
        participantToSubmit.termsOfUse = participant.termsOfUse;

        return $http.post(url + 'events/' + eventId + '/participants', participantToSubmit);
      }

      function reset() {

        angular.forEach(participant.setsCollection, function (set) {
          set.checked = false;
        });

        participant.name = '';
        participant.termsOfUse = null;
        participant.setsCollection = [];
        participant.tagsCollection = [];
        participant.customTagsCollection = [];
      }

      function deleteCheckedProp() {
        var l = participant.tagsCollection.length
          , i = 0;

        for (i; i < l; i++) {
          var currObj = participant.tagsCollection[i];

          delete currObj['checked'];
        }
      }

      function removeTagFromParticipant(tag) {
        var l, currentId;

        if (tag !== undefined) {
          l = participant.tagsCollection.length,
            currentId = tag.id;

          for (var i = 0; i < l; i++) {
            if (participant.tagsCollection[i].id === currentId) {
              participant.tagsCollection.splice(i, 1);
              return;
            }
          }
        }
      }

      function addTagToParticipant(tag) {
        var l = participant.tagsCollection.length,
          currentId = tag.id,
          alreadyIn = false;

        if (tag !== undefined) {
          for (var i = 0; i < l; i++) {
            if (participant.tagsCollection[i].id === currentId) {
              alreadyIn = true;

            }
          }

          if (!alreadyIn) {
            participant.tagsCollection.push(tag);
          }
        }
      }

      function addCustomTagToParticipant(customTagLabel) {
        var newCustomTag = {};

        newCustomTag.label = customTagLabel;
        newCustomTag.checked = true;
        newCustomTag.id = createCustomTagId(customTagLabel);

        participant.tagsCollection.push(newCustomTag);
      }

      function createCustomTagId(customTagLabel) {
        var currStr = customTagLabel.replace(/\s/g, '_');
        var currStrLow = currStr.toLowerCase();

        return currStrLow;
      }

      function addSetToParticipant(set) {
        var l = participant.setsCollection.length,
          currentSetId = set.id,
          alreadyIn = false;

        if (set !== undefined) {
          for (var i = 0; i < l; i++) {
            if (participant.setsCollection[i].id === currentSetId) {
              alreadyIn = true;
            }
          }
        }

        if (!alreadyIn) {
          participant.setsCollection.push(set);
          set.checked = true;
        }
      }

      function removeSetFromParticipant(set) {
        var l = participant.setsCollection.length,
          currentId = set.id;

        if (set !== undefined) {
          for (var i = 0; i < l; i++) {
            if (participant.setsCollection[i].id === currentId) {
              participant.setsCollection.splice(i, 1);
              set.checked = false;
            }
          }
        }
      }

      return {
        participant: participant,
        addSetToParticipant: addSetToParticipant,
        removeSetFromParticipant: removeSetFromParticipant,
        reset: reset,
        removeTagFromParticipant: removeTagFromParticipant,
        addTagToParticipant: addTagToParticipant,
        addCustomTagToParticipant: addCustomTagToParticipant,
        addParticipantToEvent: addParticipantToEvent
      };
    }]);
