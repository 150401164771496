angular.module('munchableApp')
  .factory('createEventService', [
    '$http',
    'api',
    function ($http, api) {
      var newEvent = {
        host: ''
      },
        url = api + 'events';

      function resetEvent() {
        newEvent = {

        };

        return newEvent;
      }

      function addEventToDataBase() {
        //return $http.post(url, newEvent);

        $http.defaults.transformRequest.unshift(checkDate);
        return $http({
          method: 'POST',
          url: url,
          data: newEvent
        })
      }

      function getEvent() {
        return newEvent;
      }

      function setEvent(objectUpdate) {
        if (!objectUpdate) {
          return
        } else {
          if (objectUpdate.title) {
            newEvent.title = objectUpdate.title;
          }
          if (objectUpdate.host) {
            newEvent.host = objectUpdate.host;
          }
          if (objectUpdate.email) {
            newEvent.email = objectUpdate.email;
          }
          if (objectUpdate.description) {
            newEvent.description = objectUpdate.description;
          }
          if (objectUpdate.eventUrl) {
            newEvent.eventUrl = objectUpdate.eventUrl;
          }
          if (objectUpdate.editUrl) {
            newEvent.editUrl = objectUpdate.editUrl;
          }
          if (objectUpdate.date) {
            newEvent.date = objectUpdate.date;
          }
          if (objectUpdate.categories) {
            newEvent.categories = objectUpdate.categories;
          }
          if (objectUpdate.participants) {
            newEvent.participants = objectUpdate.participants;
          }
          if (objectUpdate.createdAt) {
            newEvent.createdAt = objectUpdate.createdAt;
          }
        }
      }

      //helper

      function checkDate(request) {
        if (request.date && request.date === null) {
          delete request.date;
        } else if (request.date === null) {
          delete request.date;
        }

        return request;
      }

      return {
        getEvent: getEvent,
        setEvent: setEvent,
        resetEvent: resetEvent,
        addEventToDataBase: addEventToDataBase

      };
    }]);
