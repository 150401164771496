/*
TYPE: directive
NAME: 
USAGE: 

*/

angular
  .module('munchableApp')
  .directive('datepicker', [
    '$compile',
    '$locale',
    '$filter',
    function ($compile, $locale, $filter) {
      var A_DAY_IN_MILLISECONDS = 86400000;

      function link(scope) {
        var dateMinLimit,
          dateMaxLimit,
          date = new Date(),
          datetime = $locale.DATETIME_FORMATS;

        scope.$on('$destroy', kill);

        scope.prevMonth = managePrevMonth;
        scope.nextMonth = manageNextMonth;

        scope.setNewYear = setNewYear;
        scope.nextYear = manageNextYear;

        scope.setDatepickerDay = setDatepickeDay;

        scope.isSelectableMinDate = isSelectableMinDate;
        scope.isSelectableMaxDate = isSelectableMaxDate;

        scope.setDaysInMonth = setDaysInMonth;

        scope.setInputValue = manageInputValue;

        scope.month = $filter('date')(date, 'MMMM');//December-November like
        scope.monthNumber = Number($filter('date')(date, 'MM')); // 01-12 like

        scope.day = Number($filter('date')(date, 'dd')); //01-31 like
        scope.year = Number($filter('date')(date, 'yyyy'));//2014 like

        scope.currentModelMonth = null;
        scope.currentModelYear = null;
        scope.currentModelDay = null;

        scope.months = datetime.MONTH;
        scope.daysInString = ['0', '1', '2', '3', '4', '5', '6'].map(function mappingFunc(el) {
          return $filter('date')(new Date(new Date('06/08/2014').valueOf() + A_DAY_IN_MILLISECONDS * el), 'EEE');
        });

        //controller.childrenConfirmClick = addToParticipant;

        function init() {
          setDaysInMonth(scope.monthNumber, scope.year);

          scope.$watch('dateMinLimit', function dateMinLimitWatcher(value) {
            if (value) {

              dateMinLimit = value;
            }
          });
        }

        function manageNextMonth() {
          if (scope.monthNumber === 12) {
            scope.monthNumber = 1;
            //its happy new year
            scope.nextYear();
          } else {
            scope.monthNumber += 1;
          }
          //set next month
          scope.month = $filter('date')(new Date(scope.year, scope.monthNumber - 1), 'MMMM');
          //reinit days
          scope.setDaysInMonth(scope.monthNumber, scope.year);

          scope.day = undefined;
        }



        function managePrevMonth() {
          if (scope.monthNumber === 1) {
            scope.monthNumber = 12;
            //its happy new year
            scope.prevYear();
          } else {
            scope.monthNumber -= 1;
          }
          //set next month
          scope.month = $filter('date')(new Date(scope.year, scope.monthNumber - 1), 'MMMM');
          //reinit days
          scope.setDaysInMonth(scope.monthNumber, scope.year);

          scope.day = undefined;
        }

        function setNewYear(year) {
          //deactivate selected day
          scope.day = undefined;
          if (dateMaxLimit && scope.year < Number(year)) {
            if (!scope.isSelectableMaxYear(year)) {
              return;
            }
          } else if (dateMinLimit && scope.year > Number(year)) {
            if (!scope.isSelectableMinYear(year)) {
              return;
            }
          }

          scope.year = Number(year);
          scope.setDaysInMonth(scope.monthNumber, scope.year);
          scope.paginateYears(year);
        }

        function manageNextYear() {
          scope.year = Number(scope.year) + 1;
        }

        scope.prevYear = function managePrevYear() {
          scope.year = Number(scope.year) - 1;
        };

        function manageInputValue() {

          if (scope.isSelectableMinDate(scope.year + '/' + scope.monthNumber + '/' + scope.day)
            && scope.isSelectableMaxDate(scope.year + '/' + scope.monthNumber + '/' + scope.day)) {

            var modelDate = new Date(scope.year + '/' + scope.monthNumber + '/' + scope.day);

            scope.date = modelDate;

            scope.currentModelMonth = scope.monthNumber;
            scope.currentModelYear = scope.year;
            scope.currentModelDay = scope.day;

          } else {
            return false;
          }
        }

        function setDaysInMonth(month, year) {
          var i
            , limitDate = new Date(year, month, 0).getDate()
            , firstDayMonthNumber = new Date(year + '/' + month + '/' + 1).getDay()
            , lastDayMonthNumber = new Date(year + '/' + month + '/' + limitDate).getDay()
            , prevMonthDays = []
            , nextMonthDays = []
            , howManyNextDays
            , howManyPreviousDays
            , monthAlias;

          scope.days = [];

          for (i = 1; i <= limitDate; i += 1) {
            scope.days.push(i);
          }

          //get previous month days is first day in month is not Sunday
          if (firstDayMonthNumber !== 0) {
            howManyPreviousDays = firstDayMonthNumber;

            //get previous month
            if (Number(month) === 1) {
              monthAlias = 12;
            } else {
              monthAlias = month - 1;
            }

            //return previous month days
            for (i = 1; i <= new Date(year, monthAlias, 0).getDate(); i += 1) {
              prevMonthDays.push(i);
            }

            //attach previous month days
            scope.prevMonthDays = prevMonthDays.slice(-howManyPreviousDays);
          } else {
            //no need for it
            scope.prevMonthDays = [];
          }

          //get next month days is first day in month is not Sunday
          if (lastDayMonthNumber < 6) {

            howManyNextDays = 6 - lastDayMonthNumber;
            //get previous month

            //return next month days
            for (i = 1; i <= howManyNextDays; i += 1) {

              nextMonthDays.push(i);
            }
            //attach previous month days
            scope.nextMonthDays = nextMonthDays;
          } else {
            //no need for it
            scope.nextMonthDays = [];
          }
        }

        function setDatepickeDay(day) {
          scope.day = Number(day);
          scope.setInputValue();
          //scope.hideCalendar();
        }

        function isSelectableMinDate(aDate) {
          //if current date
          if (!!dateMinLimit &&
            !!new Date(dateMinLimit) &&
            new Date(aDate).getTime() < new Date(dateMinLimit).getTime()) {

            return false;
          }

          return true;
        }

        function isSelectableMaxDate(aDate) {

          //if current date
          if (!!dateMaxLimit &&
            !!new Date(dateMaxLimit) &&
            new Date(aDate).getTime() > new Date(dateMaxLimit).getTime()) {

            return false;
          }

          return true;
        }

        function kill() {

        }

        init();
      }

      return {
        restrict: 'E',
        link,
        require: '^popUp',
        scope: {
          'dateSet': '@',
          'dateMinLimit': '@',
          'dateMaxLimit': '@',
          'date': '='
        },
        template: require('./datepicker.tpl.html')
      }

    }])
  ;

