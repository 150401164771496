import { header, slides } from './participantCreatorSlides'

angular
  .module('munchableApp')
  .directive('participantCreator', 

    function () {
      function controller($scope, participantService, analyticsService) {
        $scope.currentParticipant = participantService.participant;

        $scope.tags = $scope.currentParticipant.tagsCollection;
        $scope.currentSlides = slides;
        $scope.currentHeader = header;

        $scope.noNoListOpen = false;
        $scope.openNoNoList = openNoNoList;

        $scope.$on('$destroy', kill);

        function init() {
          analyticsService.trackEvent(
            slides[0].tracking.event,
            slides[0].tracking.label
          );
        }

        function openNoNoList() {
          $scope.noNoListOpen = true;
        }

        function kill() {
          $scope.tags = null;
          $scope.currentSlides = null;
        }

        init();
      }

      return {
        controller: controller,
        restrict: 'E',
        template: require('./participantCreator.tpl.html')
      }
    });
