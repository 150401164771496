const slides = [
  {
    templateLink: require('./slides/eventCreator.slide1.tpl.html'),
    slideBlocked: true,
    id: 'host',
    label: 'Wer und Was',
    tracking: {
      event: 'create event',
      label: 'slide1 - who'
    }
  },
  {
    templateLink: require('./slides/eventCreator.slide2.tpl.html'),
    slideBlocked: false,
    id: 'details',
    label: 'Details',
    tracking: {
      event: 'create event',
      label: 'slide2 - details'
    }
  },
  {
    templateLink: require('./slides/eventCreator.slide3.tpl.html'),
    slideBlocked: false,
    id: 'summary',
    label: 'Zusammenfassung',
    tracking: {
      event: 'create event',
      label: 'slide3 - summary'
    }
  }
];

const header = {
  customBox: ''
}

export {
  slides,
  header
}
