const slides = [
  {
    templateLink: require('./slides/participantCreator.slide1.tpl.html'),
    slideBlocked: false,
    id: 'type',
    label: 'Typ',
    tracking: {
      event: 'create participant',
      label: 'slide1 - typ'
    },
  },
  {
    templateLink: require('./slides/participantCreator.slide2.tpl.html'),
    slideBlocked: false,
    id: 'allergies',
    label: 'Allergien',
    tracking: {
      event: 'create participant',
      label: 'slide2 - Allergien'
    }
  },
  {
    templateLink: require('./slides/participantCreator.slide3.tpl.html'),
    slideBlocked: false,
    id: 'search',
    label: 'Suche',
    tracking: {
      event: 'create participant',
      label: 'slide3 - Suche'
    }
  },
  {
    templateLink: require('./slides/participantCreator.slide4.tpl.html'),
    slideBlocked: true,
    id: 'name',
    label: 'Name',
    tracking: {
      event: 'create participant',
      label: 'slide4 - Name'
    }
  },
  {
    templateLink: require('./slides/participantCreator.slide5.tpl.html'),
    slideBlocked: false,
    id: 'summary',
    label: 'Zusammenfassung',
    tracking: {
      event: 'create participant',
      label: 'slide5 - Zusammenfassung'
    }
  }
];

const header = {
  customBox: 'nono'
}

export {
  slides,
  header
}