/*
TYPE: directive
NAME: content-slider

USAGE:
use <content-slider> tag and together with a data, which defines the slides, a slider will be created. Each slide is a content-slide directive. Those are described in the specific file.

TO-DO:
- make slide header sticky
*/

import { TweenMax, Power4 } from 'gsap';

angular
  .module('munchableApp')
  .directive('contentSlider',
    function () {
      function controller($scope, $element, $timeout, $window, analyticsService) {
        // define private properties
        var self = this,
          elements = getElements(),
          allowSlide = true,
          blockSlideBothWays = false,
          allSlides = $scope.slides,
          allSlidesLength = allSlides.length,
          sliding = false,
          currentStepsHeight = 0,
          heightTimer = null,
          heightDelay = 200
          ;

        // define scope properties
        $scope.currentSlideNum = 1;
        $scope.allSlidesLength = allSlides.length;
        $scope.slideTo = slideTo;
        $scope.slideNext = slideNext;
        $scope.slidePrev = slidePrev;
        $scope.$on('$destroy', kill);

        $scope.$on('wantSlideNext', slideNext);

        // define public props
        self.setStepsHeight = setStepsHeight;
        self.setBlockSlide = setBlockSlide;
        self.setAllowSlide = setAllowSlide;
        self.slideTo = slideTo;
        self.resetSlides = resetSlides;

        // all private methods
        function init() {
          checkSlideBlocked($scope.currentSlideNum - 1);
          angular.element($window).on('resize', handleWindowResize);

          //show custom header elements
          if ($scope.header.customBox === 'nono') {
            $scope.showNono = true;
          }
        }

        function resetSlides() {
          $scope.$broadcast('resetSlides');
        }

        // slide events
        function slideTo(slideNum, instantly, overwriteBlocking) {
          var i = $scope.currentSlideNum;

          if (overwriteBlocking) {
            setAllowSlide(slideNum);
          }

          if (blockSlideBothWays) {
            return
          }

          //quick check if there´s any blocked slide on my way
          for (i; i < slideNum; i++) {
            if (allSlides[i].slideBlocked === true && slideNum > 1 && slideNum > i) {
              slide(i + 1, instantly);
              $scope.$broadcast('slideNotAllowed');
              return
            }
          }

          if (allowSlide || slideNum <= ($scope.currentSlideNum - 1)) {
            $scope.$broadcast('slideAllowed');
            slide(slideNum);
          } else {
            $scope.$broadcast('slideNotAllowed');
          }
        }

        function slideNext() {
          if (blockSlideBothWays) {
            return
          }

          if (allowSlide) {
            var num = $scope.currentSlideNum + 1;
            $scope.$broadcast('slideAllowed');
            slide(num);
          } else {
            $scope.$broadcast('slideNotAllowed');
          }
        }

        function slidePrev() {
          if (blockSlideBothWays) {
            return
          }

          var num = $scope.currentSlideNum - 1;
          $scope.$broadcast('slideAllowed');
          slide(num);
        }

        // change handler
        function handleWindowResize() {
          setStepsHeight();
        }

        function setAllowSlide(slideIndex) {
          allowSlide = true;
          blockSlideBothWays = false;
          if (slideIndex) {
            allSlides[slideIndex].slideBlocked = false;
          } else {
            allSlides[$scope.currentSlideNum - 1].slideBlocked = false;
          }
        }

        function setBlockSlide(slideIndex, both) {
          if (slideIndex === $scope.currentSlideNum || slideIndex === undefined) {
            allowSlide = false;
          }

          if (slideIndex) {
            allSlides[slideIndex].slideBlocked = true;
          } else {
            allSlides[$scope.currentSlideNum - 1].slideBlocked = true;
          }

          if (both) {
            blockSlideBothWays = true;
          }
        }

        function slide(slideNum, instantly) {
          if (slideNum > 0 && slideNum <= allSlidesLength && !sliding) {
            //var time =  Math.abs(slideNum - $scope.currentSlideNum) * 0.5;

            var time = instantly ? 0 : 0.5;

            sliding = true;
            // we don´t use translate3d at the moment because it sucks with z-index
            TweenMax.to(
              elements.slideWrapper, time,
              {
                css: {
                  left: ((slideNum - 1) * -100 + '%')
                },
                ease: Power4.easeInOut,
                onStart: setStepsHeight,
                onStartParams: [],
                onComplete: function () {
                  toggleSlidingStatus()
                  analyticsService.trackEvent(
                    allSlides[slideNum - 1].tracking.event,
                    allSlides[slideNum - 1].tracking.label
                  )
                },
                onCompleteParams: [false]
              });
            $scope.currentSlideNum = slideNum;
            checkSlideBlocked($scope.currentSlideNum - 1);
          }
        }

        function checkSlideBlocked(slideNum) {
          if (allSlides[slideNum].slideBlocked) {
            allowSlide = false;
          } else {
            allowSlide = true;
          }
        }

        function setStepsHeight(instantly) {
          if (elements.slides[$scope.currentSlideNum - 1] !== undefined) { // ncochmal drüber nachdenken

            var newHeight = elements.slides[$scope.currentSlideNum - 1].offsetHeight;
            var animationTime = instantly === true ? 0 : .2;

            if (newHeight === currentStepsHeight) { return }

            TweenMax.to(elements.slideWrapper, animationTime, {
              css: { height: newHeight },
              ease: Power4.easeOut
            });

            currentStepsHeight = newHeight;

          }
        }


        function toggleSlidingStatus() {
          sliding = !sliding;
        }

        function kill() {
          $scope.allSlides = null;
          elements = null;
          angular.element($window).off('resize', handleWindowResize);
        }

        // Getter Methods
        function getElements() {
          var elements = {};

          elements.slideWrapper = $element[0].getElementsByClassName('contentSlider__steps')[0];
          elements.slides = $element[0].getElementsByTagName('content-slide');


          return elements;
        }

        init();
      }

      return {
        restrict: 'E',
        replace: true,
        scope: {
          slides: '=',
          header: '='
        },
        controller: controller,
        template: require('./contentSlider.tpl.html')
      }
    })
  ;

