angular
  .module('munchableApp')
  .directive('preventTabDefault',
    function () {
      function link(scope, element) {
        element.bind('keydown keypress', function (event) {
          if (event.which === 9) {
            event.preventDefault();
          }
        });

        scope.$on('$destroy', function () {
          element.unbind('keydown keypress');
        });
      }

      return {
        restrict: 'A',
        link
      }
    });

