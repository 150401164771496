angular.module('munchableApp')
.directive('mainFooter', [
    function() {
        return {
            restrict: 'E',
            replace: true,
            template: require('./mainFooter.tpl.html')
        }
}])
;

