/*
TYPE: directive
NAME: share
USAGE: Use to display round share button.
ATTRIBUTES:
*/

angular
  .module('munchableApp')
  .directive('shareUrl', [
    function () {
      function link(scope, element) {
        scope.isOpen = false;

        element[0].addEventListener('click', openShareButton);

        function openShareButton() {
          scope.isOpen = true;
          scope.$apply();
        }
      }

      return {
        scope: {
          url: '@'
        },
        restrict: 'E',
        link,
        replace: true,
        template: require('./shareUrl.tpl.html')
      }
    }])
  ;


