angular.module('munchableApp')
.service('createFactsCollection', [
    function() {
        var currentGuestList;
        
        function create(guestList) {
            currentGuestList = guestList;
            var currentFactsCollection = {};

            for (var i = 0; i < currentGuestList.length; i++) { 
                if(currentGuestList[i].categories !== undefined) {
                    for (var k = 0; k < currentGuestList[i].categories.length; k++) {
                        if(currentFactsCollection[currentGuestList[i].categories[k].label]) {
                            currentFactsCollection[currentGuestList[i].categories[k].label] += 1;
                        } else {
                            currentFactsCollection[currentGuestList[i].categories[k].label] = 1;    

                        }                
                    }                      
                }      
            }      
            
            
            return currentFactsCollection;
        }

        return {
            create: create
        }
    }
]);