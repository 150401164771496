angular.module('munchableApp')
  .controller('participantCreator.slide5.controller', [
    '$scope',
    'participantService',
    '$timeout',
    'tagsService',
    'analyticsService',

    function ($scope, participantService, $timeout, tagsService, analyticsService) {
      var timer = null;

      $scope.participant = participantService.participant;
      $scope.submitParticipant = submitParticipant;

      $scope.removeTagToParticipant = removeTagToParticipant;

      $scope.participantAdded = false;

      $scope.stepIsLoading = false;

      $scope.setStepsHeight = $scope.$parent.setStepsHeight;

      $scope.tryAgain = tryAgain;

      $scope.resetParticipant = resetParticipant;

      $scope.$on('$destroy', kill);

      function removeTagToParticipant(tag) {
        participantService.removeTagFromParticipant(tag);
        tag.checked = false;
      }

      function submitParticipant() {
        analyticsService.trackEvent('Create Participant', 'slide5: Submit clicked')

        if ($scope.participant.termsOfUse) {

          $scope.stepIsLoading = true;

          participantService.addParticipantToEvent().then(function (result) {
            $scope.participantAdded = true;

            timer = $timeout(function () {
              $scope.$parent.setStepsHeight();
              $scope.$parent.setBlockSlide(null, true);
            }, 0);
            $scope.stepIsLoading = false;

            $scope.participant = participantService.participant;
          }, function (err) {
            $scope.errorType = err;
            $scope.error = true;

            $scope.$parent.setStepsHeight();
            $scope.stepIsLoading = false;
          });
        }
      }


      function tryAgain() {
        $scope.errorType = null;
        $scope.error = false;

        $scope.$parent.setStepsHeight();
      }

      function resetParticipant() {
        participantService.reset();

        $scope.participant = participantService.participant;
        $scope.slideTo(1, true, true);

        $scope.participantAdded = false;
        tagsService.uncheckAllTags();

        $scope.resetSlides();

      }

      function kill() {
        if (timer) {
          $timeout.cancel(timer);
        }
      }
    }]);
