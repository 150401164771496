angular.module('munchableApp')
.controller('participantCreator.slide4.controller', [
    '$scope',
    'participantService',
    '$timeout',
    '$routeParams',

    function($scope, participantService, $timeout) {

        $scope.participant = participantService.participant;


        $scope.checkNameInput = checkNameInput;
        $scope.formChecked = false;

        $scope.event = {};
        $scope.error = null;
        $scope.errorType = null;

        $scope.anonym = false;

        $scope.checkAnonym = checkAnonym;
        $scope.$on('slideNotAllowed', checkNameInput);
        $scope.$on('resetSlides', resetSlide);

        function checkAnonym() {
            $scope.$parent.setStepsHeight(true);

            if($scope.anonym) {
                $scope.participant.name = 'Anonymer Kauz';
                $scope.setAllowSlide();

            } else {
                $scope.participant.name = '';
                $scope.setBlockSlide();
            }
        }

        function checkNameInput() {
            if($scope.participantCreatorFormAddName.$valid) {
                $scope.setAllowSlide();
            } else {
                $scope.setBlockSlide();
                $scope.formChecked = true;

                $timeout(function() {
                    $scope.formChecked = false;
                }, 1000);
            }
        }

        function resetSlide() {
            $scope.setBlockSlide($scope.slideIndex);
            $scope.anonym = false;
        }
}]);
