/*
TYPE: directive
NAME: popUp

USAGE: Use as element <pop-up>. Add custom content inside this tag, ng-transclude should take care of it.
Content will get a srollbar if its height does not fit into the window height.

ATTRS:
 - discard-label: label of discard button
 - confirm-label: label of confirm button
 - active: boolean to open popup
 - discard-callback: callback which will be called on cancel popup

*/
import { TweenMax } from 'gsap';

angular
  .module('munchableApp')
  .directive('popUp', [
    function () {
      function controller($scope, $element) {
        var self = this,
          discard = $element[0].getElementsByClassName('popUp__discard')[0],
          confirm = $element[0].getElementsByClassName('popUp__confirm')[0],
          content = $element[0].getElementsByClassName('popUp__content')[0],
          container = $element[0].getElementsByClassName('popUp__container')[0],
          contentWrapper = $element[0].getElementsByClassName('popUp__contentWrapper')[0],
          timer;

        $scope.$on('$destroy', kill);


        function init() {
          $scope.$watch('active', function (newValue) {
            if ($scope.active) {
              setPopupActive();
            } else {
              setPopupInactive();
            }
          });

          if ($scope.confirmLabel === '' || $scope.confirmLabel === undefined) {
            $element[0].getElementsByClassName('popUp__confirm')[0].style.display = 'none';
          }

          switch ($scope.position) {
            case 'bottom': {
              $element.addClass('popUp--pos-bottom');
              return
            }

            default:
              $element.addClass('popUp--pos-center');
          }

          switch ($scope.type) {
            case 'alert': $element.addClass('popUp--alert');
          }
        }

        function closePopup() {
          setPopupInactive();
          $scope.active = false;
          $scope.$apply();
        }

        function setPopupActive() {
          discard.addEventListener('click', discardClick);
          confirm.addEventListener('click', confirmClick);
          angular.element(window).on('resize', handleWindowResize);
          TweenMax.to($element, 0.1, { css: { display: 'block' }, onComplete: showContent });
        }

        function setPopupInactive() {
          TweenMax.to($element, 0.1, { css: { display: 'none' }, onComplete: hideContent });
          discard.removeEventListener('click', closePopup);
          confirm.removeEventListener('click', confirmClick);
          angular.element(window).off('resize', handleWindowResize);
        }

        function showContent() {
          $element.addClass('popUp--active');
          checkScroll();
        }

        function hideContent() {
          $element.removeClass('popUp--active');
          contentWrapper.style.height = 'auto';
          contentWrapper.style.overflow = 'auto';
        }

        function confirmClick() {
          if (self.childrenConfirmClick !== undefined) {
            self.childrenConfirmClick();
          }

          if ($scope.confirmCallback) {
            $scope.confirmCallback();
          }
          closePopup();
        }

        function discardClick() {
          if ($scope.discardCallback) {
            $scope.discardCallback();
          }

          closePopup();
        }

        function checkScroll() {
          var currHeight = container.offsetHeight,
            winHeight = window.innerHeight - 100;

          if (winHeight < currHeight) {
            var buttonHeight = $element[0].getElementsByClassName('popUp__buttons')[0].offsetHeight
              ;

            contentWrapper.style.height = winHeight - buttonHeight - 60 + 'px';
            contentWrapper.style.overflow = 'hidden';
            contentWrapper.style.overflowY = 'scroll';
          } else {
            contentWrapper.style.height = 'auto';
            contentWrapper.style.overflow = 'hidden';
          }

        }

        function handleWindowResize() {
          checkScroll();
        }

        function kill() {
          contentWrapper.style.height = 'auto';
          contentWrapper.style.overflow = 'auto';
          discard.removeEventListener('click', discardClick);
          confirm.removeEventListener('click', confirmClick);
          angular.element(window).off('resize', handleWindowResize);
        }

        init();
      }

      return {
        scope: {
          active: '=',
          discardLabel: '@',
          confirmLabel: '@',
          discardCallback: '=',
          confirmCallback: '=',
          title: '@',
          type: '@',
          position: '@'
        },
        restrict: 'AE',
        transclude: true,
        replace: true,
        controller,
        template: require('./popUp.tpl.html')
      }
    }
  ]);

