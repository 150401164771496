angular
  .module('munchableApp')
  .directive('smallSwitch', [
    '$timeout',
    function ($timeout) {
      function link(scope) {
        var timer;

        if (scope.callback) {
          scope.$watch(
            'on',
            function (newValue, oldValue) {
              if (newValue !== oldValue) {
                timer = $timeout(function () {
                  scope.callback(true)
                }, 0);
              }
            }
          );
        }

        scope.$on('$destroy', function () {
          if (scope.timer) {
            $timeout.cancel(timer);
            timer = null;
            scope.watch('on', function () { });
          }
        });
      }

      return {
        scope: {
          on: '=',
          callback: '='
        },
        restrict: 'E',
        link,
        replace: true,
        template: require('./smallSwitch.tpl.html')
      }
    }])
  ;


