/**
 * TAG DIRECTIVE
 *
 * HOW-TO-USE:
 *
 *
 * ATTRIBUTS
 *
 *
 * TO-DO
 *
 * */

angular
  .module('munchableApp')
  .directive('tag', [
    function () {
      return {
        scope: {
          label: '=',
          checked: '='
        },
        restrict: 'E',
        replace: true,
        template: require('./tag.tpl.html')
      }
    }
  ]);
