angular
  .module('munchableApp')
  .directive('noNoListActivator', [
    'participantService',
    '$timeout',
    function (participantService, $timeout) {

      function link(scope, element) {
        var _timer;

        scope.$on('$destroy', kill);
        scope.currentParticipant = participantService.participant;


        function init() {
          //scope.tags =  $scope.currentParticipant.tagsCollection;

          scope.$watch(
            'tags.length',
            function (oldVal, newVal) {
              if (oldVal !== newVal) {
                element.addClass('counter--valueChange');

                _timer = $timeout(function () {
                  element.removeClass('counter--valueChange');
                }, 300);
              }
            }
          );
        }


        function kill() {
          scope.$watch('counter', function () { });
          $timeout.cancel(_timer);
          _timer = null;
          scope.tags = null;
        }

        init();
      }

      return {
        restrict: 'E',
        replace: true,
        link,
        template: require('./noNoListActivator.tpl.html')
      }
    }])
  ;

