/* directive for sliders
HOW-TO-INIT:
Add attributen slide-to="" to a parent container. All immediately following elements are children. So Add for every slide a div. Inside them
do what you have to do.

HOW-TO-SLIDE:
Control the current slide by changing the value of the slide-to attribute. You can change it however you want, value is watched
inside the directive

*/

angular
  .module('munchableApp')
  .directive('contentSlide', [
    '$compile',
    '$http',
    '$templateCache',
    '$timeout',
    function ($compile) {
      function link(scope, element, attrs, controller) {
        scope.setStepsHeight = controller.setStepsHeight;
        scope.setBlockSlide = controller.setBlockSlide;
        scope.setAllowSlide = controller.setAllowSlide;
        scope.slideTo = controller.slideTo;
        scope.resetSlides = controller.resetSlides;

        var tpl = attrs.template;
        element.html(tpl);
        $compile(element.contents())(scope);
      }

      return {
        scope: {
          active: '=',
          slideLength: '=',
          slideIndex: '='
        },
        restrict: 'E',
        require: '^contentSlider',
        link
      }
    }
  ]);
