angular
  .module('munchableApp')
  .directive('selectOnClick',
    function () {
      function link(scope, element) {
        element.on('click', function () {
          this.select();
        });
      }

      return {
        restrict: 'A',
        link
      }
    }
  );
