angular
  .module('munchableApp')
  .directive('keyEnterCallback',
    function () {
      function link(scope, element) {
        element.bind('keydown keypress', function (event) {
          if (event.which === 13) {
            scope.enterCallback();
          }
        });

        scope.$on('$destroy', function () {
          element.unbind('keydown keypress');
        });
      }

      return {
        restrict: 'A',
        link,
        scope: {
          enterCallback: '=keyEnterCallback'
        }
      }
    })
  ;

