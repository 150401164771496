angular
  .module('munchableApp')
  .factory('stateRegistry', function () {
    var STATE_KEYS = {
      'initialized': false
    };
    var STATE_SET = 'set';
    var STATE_UNSET = 'unset';
    var registered = {};

    /**
     * register to a statusKey to have a callback triggered whenever this state changes
     *
     * @param statusKey | {String} -> callback will be registered to this key
     * @param callback | {Function} -> callback to be triggered when status of statusKey changes
     */
    function registerToStatus(statusKey, callback) {
      if (!angular.isFunction(callback)) {
        throw Error('callback must be a function');
      }

      if (!angular.isArray(registered[statusKey])) {
        registered[statusKey] = [];
      }

      registered[statusKey].push(callback);

      //if this status was already set before, call this callback
      // with current statusValue
      if (STATE_KEYS[statusKey] !== null) {
        if (STATE_KEYS[statusKey]) {
          callback(statusKey, STATE_SET);
        } else {
          callback(statusKey, STATE_UNSET);
        }
      }
    }

    /**
     * public function to set a statusKey true and notify all registered callbacks
     *
     * @param statusKey | {String} -> this statusKey will be set true in STATE_KEYS for future purpose
     */
    function setStatus(statusKey) {
      if (!checkIfStatusExists(statusKey)) {
        throw Error('status not found in registered status');
      }

      STATE_KEYS[statusKey] = true;
      notifyAll(statusKey, STATE_SET);
    }

    /**
     * public function to set a statusKey false and notify all registered callbacks
     *
     * @param statusKey | {String} -> this statusKey will be set false in STATE_KEYS for future purpose
     */

    function unsetStatus(statusKey) {
      if (!checkIfStatusExists(statusKey)) {
        throw Error('status not found in registered status');
      }
      STATE_KEYS[statusKey] = false;
      notifyAll(statusKey, STATE_UNSET);
    }

    ///privates
    /**
     * notifies all callbacks registered to corresponding statusKey with statusKey and type as params
     *
     * @param statusKey | {String} -> all callbacks registered to this status key will be called
     * @param value | { String} -> if status is set / unset, callbacks have to check themselves and respond to each of this type
     */
    function notifyAll(statusKey, value) {
      var callbacks = getRegisteredFor(statusKey);

      if (!callbacks) {
        return;
      }

      angular.forEach(callbacks, function (callback) {
        callback(statusKey, value);
      });
    }

    function getRegisteredFor(statusKey) {
      if (!angular.isArray(registered[statusKey])) {
        return null;
      }
      return registered[statusKey];
    }

    function checkIfStatusExists(statusKey) {
      return statusKey in STATE_KEYS;
    }

    function getStateValue(statusKey) {
      return STATE_KEYS[statusKey] === true ? STATE_SET : STATE_UNSET;
    }

    return {
      registerToStatus: registerToStatus,
      setStatus: setStatus,
      unsetStatus: unsetStatus,
      getStateValue: getStateValue
    };
  });
