angular.module('munchableApp')
.factory('analyticsService', [
  function() {
    function init() {
      window.addEventListener('hashchange', function() {
        gtag('config', 'UA-159619059-1', {
          'page_title' : window.location.hash.substr(1),
          'page_path': '/' + window.location.hash.substr(1)
        });
      });
    }

    function trackEvent(action, name, value) {
      gtag('event', action, {'event_label': name, 'value': value});
    }

    function setTracking(shallTrack) {
      window['ga-disable-UA-159619059-1'] = !shallTrack;
      localStorage.setItem('tracking-note-read', shallTrack);
    }

    return {
      init: init,
      trackEvent: trackEvent,
      setTracking: setTracking
    };
  }
]);
