import { header, slides } from './eventCreatorSlides'

angular
  .module('munchableApp')
  .directive('eventCreator',
    function () {
      function controller($scope) {
        $scope.currentSlides = slides;
        $scope.currentHeader = header;
      }

      return {
        controller: controller,
        restrict: 'E',
        template: require('./eventCreator.tpl.html')
      }
    });
