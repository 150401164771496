import './index.scss';

import './assets/icons/add.svg';
import './assets/icons/arrow-down.svg';
import './assets/icons/arrow-left.svg';
import './assets/icons/arrow-right.svg';
import './assets/icons/arrow-up.svg';
import './assets/icons/calendar.svg';
import './assets/icons/close.svg';
import './assets/icons/copy.svg';
import './assets/icons/create.svg';
import './assets/icons/delete.svg';
import './assets/icons/participant.svg';
import './assets/icons/search.svg';

import './app/app';
import './app/admin/admin.controller';
import './app/create/create.controller';
import './app/event/event.controller';

import './components/contentSlider/contentSlider.directive';
import './components/contentSlider/contentSlide.directive';
import './components/datepicker/datepicker.directive';
import './components/eventCreator/eventCreator.controller';
import './components/eventCreator/eventCreator.directive';
import './components/eventCreator/slides/eventCreator.slide1.controller';
import './components/eventCreator/slides/eventCreator.slide2.controller';
import './components/eventCreator/slides/eventCreator.slide3.controller';
import './components/inputs/keyEnterCallback';
import './components/inputs/preventTabDefault.directive';
import './components/inputs/selectOnClick.directive';
import './components/inputs/stopMaxLength.directive';
import './components/mainFooter/mainFooter.controller';
import './components/noNoList/noNoList.directive';
import './components/noNoList/noNoListActivator.directive';
import './components/participantCreator/participantCreator.directive';
import './components/participantCreator/slides/participantCreator.slide1.controller';
import './components/participantCreator/slides/participantCreator.slide2.controller';
import './components/participantCreator/slides/participantCreator.slide3.controller';
import './components/participantCreator/slides/participantCreator.slide4.controller';
import './components/participantCreator/slides/participantCreator.slide5.controller';
import './components/popUp/popUp.directive';
import './components/set/set.directive';
import './components/setCollection/setCollection.directive';
import './components/shareUrl/shareUrl.directive';
import './components/switch/bigSwitch.directive';
import './components/switch/smallSwitch.directive';
import './components/tag/tag.directive';
import './components/tagCounter/tagCounter.directive';
import './components/tagFilter/tagFilter.directive';
import './components/trackingNote/trackingNote.directive';
import './components/utils/domReady.directive';
import './components/utils/sticky.directive';

import './services/analytics.service';
import './services/createEvent.service';
import './services/createFactsCollection.service';
import './services/event.service';
import './services/participant.service';
import './services/sets.service';
import './services/stateRegistry.factory';
import './services/tags.service';