angular
  .module('munchableApp')
  .directive('noNoList', [
    'participantService',
    'tagsService',
    function (participantService, tagsService) {
      function controller($scope) {
        $scope.currentParticipant = participantService.participant;

        $scope.allTags = tagsService.getAllTags();

        $scope.removeSetFromParticipant = removeSetFromParticipant;
        $scope.removeTagFromParticpant = removeTagFromParticpant;

        $scope.$on('$destroy', kill);

        function removeSetFromParticipant(set) {
          var l = set.tags.length;

          for (var i = 0; i < l; i++) {
            participantService.removeTagFromParticipant(set.tags[i]);
            tagsService.uncheckTag(set.tags[i].id);
          }

          participantService.removeSetFromParticipant(set);
        }

        function removeTagFromParticpant(tag) {
          participantService.removeTagFromParticipant(tag);
          tagsService.uncheckTag(tag.id);
        }

        function kill() {
          $scope.allTags = null;
        }
      }

      return {
        restrict: 'E',
        replace: true,
        controller,
        template: require('./noNoList.tpl.html')
      }
    }
  ]);
