/**
 * FULL WIDTH SWITCH DIRECTIVE
 *
 * HOW-TO-USE:
 * <big-switch callback="" on=""></big-switch>
 *
 * ATTRIBUTES
 * {function} optional callback - when it´s on this is triggerd
 * {boolean} on - this boolean will be on/off changed with switch / when it´s on, callback gets triggered
 * {string} on-label - label on the true side (left side)
 *  {string} off-label - label on the false side (right side)
 *
 * */

angular
  .module('munchableApp')
  .directive('bigSwitch', [
    '$timeout',
    function ($timeout) {
      function link(scope) {
        var timer;

        if (scope.callback) {
          scope.$watch(
            'on',
            function (newValue, oldValue) {
              if (newValue !== oldValue) {
                timer = $timeout(function () {
                  scope.callback(true)
                }, 0);
              }
            }
          );
        }

        scope.$on('$destroy', function () {
          if (scope.callback) {
            $timeout.cancel(timer);
            timer = null;
          }
        });
      }

      return {
        scope: {
          on: '=',
          callback: '=',
          onLabel: '@',
          offLabel: '@'
        },
        restrict: 'E',
        link,
        replace: true,
        template: require('./bigSwitch.tpl.html')
      }
    }
  ])
  ;


