angular.module('munchableApp')
.controller('eventCreator.slide1.controller', [
    '$scope',
    '$timeout',
    'createEventService',

    function($scope, $timeout, createEventService) {
        var timer;

        $scope.$on('slideNotAllowed', checkForm);
        $scope.$on('$destroy', kill);

        $scope.newEvent = createEventService.getEvent();
        $scope.checkForm = checkForm;
        $scope.formChecked = false;

        $scope.checkBlocked = checkBlocked;

        $scope.setAllowSlide = $scope.$parent.setAllowSlide;
        $scope.setBlockSlide = $scope.$parent.setBlockSlide;

        function init () {
            //dumb solution to set steps height when first slide is init
            $timeout(function() {
                $scope.$parent.setStepsHeight(true);
            },0);
        }


        function checkBlocked(valid) {
            if(valid) {
                $scope.setAllowSlide();
            } else {
                $scope.setBlockSlide();
            }
        }

        function checkForm() {
          $scope.formChecked = true;
          timer = $timeout(resetFormChecked, 1000);
        }

        function resetFormChecked() {
            $scope.formChecked = false;
        }

        function kill() {
            if (timer) {
                $timeout.cancel(timer);
            }

            $scope.$watch('eventCreatorFormGeneralInfos.$valid', function(){});
        }

        init();
}]);
