/*
TYPE: directive
NAME: set
USAGE: Use to display sets. Needs data from the set.json
ATTRIBUTES: id -> to connect right tags with it and to insert right image
            label -> for the tag label 
            version -> big or small, outputs different visual layout
*/

angular
  .module('munchableApp')
  .directive('set', [
    function() {
      return {
        scope: { 
            id: '@',
            label: '@',
            version: '@',
            selected: '='
        },
        restrict: 'E',
        replace:true,
        template: require('./set.tpl.html')
      }
    }
  ]);
