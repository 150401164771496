angular.module('munchableApp')
.controller('eventCreator.slide3.controller', [
    '$scope',
    '$window',
    'createEventService',
    '$timeout',
    '$location',
    '$translate',
    'host',
    'analyticsService',

    function($scope, $window, createEventService, $timeout, $location, $translate, host, analyticsService) {
        var timer = null;

        $scope.slideTo = $scope.$parent.slideTo;

        $scope.newEvent = createEventService.getEvent();
        $scope.submit = submit;
        $scope.goToUpdateEvent = goToUpdateEvent;

        $scope.newEventCreated = false;
        $scope.errorType = null;
        $scope.error = null;

        $scope.stepIsLoading = false;

        $scope.setStepsHeight = $scope.$parent.setStepsHeight;

        $scope.sendCopy = false;

        $scope.checkSendCopy = checkSendCopy;

        $scope.tryAgain = tryAgain;

        $scope.clipboardSuccess = clipboardSuccess;
        $scope.clipboardFail = clipboardFail;

        $scope.$on('$destroy', kill);

        function submit () {
          analyticsService.trackEvent('create event', 'slide3: Submit clicked')
          
          if($scope.newEvent.termsOfUse) {
              $scope.stepIsLoading = true;

              createEventService.addEventToDataBase().then(function(result) {
                  createEventService.setEvent(result.data);
                  $scope.newEventCreated = true;
                  createEventService.resetEvent();

                  timer = $timeout(function() {
                      $scope.setStepsHeight();
                  }, 0);
                  $scope.stepIsLoading = false;

                  $scope.newEvent.fullEditUrl =  host + '#/event/' + $scope.newEvent.eventUrl + '/' + $scope.newEvent.editUrl;
                  $scope.newEvent.fullEventUrl =  host + '#/event/' + $scope.newEvent.eventUrl;
              }, function(err) {
                  $scope.errorType = err;
                  $scope.error = true;

                  $scope.$parent.setStepsHeight();
                  $scope.stepIsLoading = false;
              });
          }
        }

        function goToUpdateEvent () {
            window.open($scope.newEvent.fullEditUrl);
        }
        /*
        used as callback function for the small switch for email notfication
         */
        function checkSendCopy() {
            $timeout(function() {
                $scope.$parent.setStepsHeight();

                if(!$scope.sendCopy) {
                    $scope.newEvent.email = undefined;
                }
            }, 0)

        }

        function tryAgain() {
            $scope.errorType = null;
            $scope.error = false;

            $timeout(function() {
                $scope.setStepsHeight();
            }, 0);
        }

        function clipboardSuccess() {
            $translate('COMPONENTS.EVENT_CREATOR.STEP3.COPY.SUCCESS').then(function (translated) {
                $window.alert(translated);
            });
        }

        function clipboardFail() {
            $translate('COMPONENTS.EVENT_CREATOR.STEP3.COPY.FAIL"').then(function (translated) {
                $window.alert(translated);
            });
        }

        function kill() {
            $timeout.cancel(timer);
        }
    }
]);
