angular.module('munchableApp')
  .directive('domReady', [
    function () {
      function link(scope) {
        angular.element(document).ready(function () {
          scope.domReady = true;
          scope.$apply();
        });
      }

      return {
        scope: {
          domReady: '='
        },
        restrict: 'A',
        link
      }
    }
  ]);
