angular
  .module('munchableApp')
  .controller('admin.controller', [
    '$scope',
    '$routeParams',
    'eventService',
    'createFactsCollection',
    '$location',
    '$timeout',
    'host',
    'analyticsService',

    function ($scope, $routeParams, eventService, createFactsCollection, $location, $timeout, host, analyticsService) {
      var allFormsValid = false,
        timer = null;

      // event data
      $scope.event = {};
      $scope.eventUpdate = {};
      $scope.editUrl = $routeParams.editID;
      $scope.eventUrl = host + '#/event/' + $routeParams.eventID;

      $scope.expandCurrentEvent = false;

      //check if there´s at least one participant
      $scope.weHaveGuests = false;

      //variables to control update form
      $scope.hideUpdateForm = hideUpdateForm;
      $scope.showUpdateForm = showUpdateForm;
      $scope.showInputs = false;
      $scope.showDatepicker = false;
      $scope.showDatepickerPopup = showDatepickerPopup;
      //$scope.currentEventTitleFormInputs = false;
      //$scope.currentEventDescriptionFormInputs = false;
      $scope.formChecked = false;
      $scope.clearDate = clearDate;
      $scope.setDate = setDate;

      //update event
      $scope.updateEvent = updateEvent;

      //helper to format the minDate of the datepicker nicely
      $scope.minDate = dateFormatter(new Date());

      //delete event
      $scope.deleteEvent = deleteEvent;
      $scope.showDeletePopUp = false;

      //delete single participant
      $scope.showParticipantDeletePopup = false;
      $scope.prepareParticipantDeletion = prepareParticipantDeletion;
      $scope.deleteParticipant = deleteParticipant;
      $scope.currentParticipantToDeleteIndex = null;

      //if any loading error occurs
      $scope.error = false;

      //key facts
      $scope.showFoodFacts = true;
      $scope.showLinkPopup = false;

      $scope.$on('$destroy', kill);

      function init() {
        //initially load event depending on id of state param // if param is provided through URL
        if ($routeParams.eventID !== undefined) {
          eventService.loadEventById($routeParams.eventID).then(function (result) {
            $scope.event = result.data;
            $scope.event.date = Date.parse($scope.event.date);

            $scope.eventUpdate.title = $scope.event.title;
            $scope.eventUpdate.date = $scope.event.date || null;
            $scope.eventUpdate.description = $scope.event.description;

            if ($scope.event.participants && $scope.event.participants.length > 0) {
              $scope.factsCollection = createFactsCollection.create($scope.event.participants);
              $scope.weHaveGuests = true;
            }
          }, function (err) {
            $scope.error = true;
            $scope.errorText = err.data;
          });
        }

        analyticsService.trackEvent('Admin survey', 'started')
      }

      function prepareParticipantDeletion(index, name) {
        $scope.showParticipantDeletePopup = true;
        $scope.currentParticipantToDelete = name;
        $scope.currentParticipantToDeleteIndex = index;
      }

      function deleteParticipant() {
        $scope.event.participants.splice($scope.currentParticipantToDeleteIndex, 1);
        eventService.updateEventParticipantslist($scope.event.participants, $scope.editUrl).then(function () {
        });
      }

      function showUpdateForm() {
        $scope.expandCurrentEvent = true;
        $scope.showInputs = true;
      }

      function hideUpdateForm() {
        $scope.expandCurrentEvent = false;
        $scope.showInputs = false;

        $scope.eventUpdate = angular.copy($scope.event);

      }

      function checkUpdateForm() {
        if ($scope.currentEventUpdate.$valid === true) {
          allFormsValid = true;

        } else {
          allFormsValid = false;
        }
      }

      function resetFormChecked() {
        $scope.formChecked = false;
      }

      function clearDate() {
        $scope.eventUpdate.date = null;
      }

      function setDate() {
        $scope.eventUpdate.date = $scope.event.date;
      }

      function showDatepickerPopup() {
        $scope.showDatepicker = true;
      }

      function updateEvent() {
        checkUpdateForm();

        if (allFormsValid) {
          updateTitle();
          updateDate();
          updateDescription();
          hideUpdateForm();
        } else {
          $scope.formChecked = true;
          timer = $timeout(resetFormChecked, 1000);
        }
      }

      function updateTitle() {
        $scope.event.title = $scope.eventUpdate.title;
        eventService.updateEventTitle($scope.event.title, $scope.editUrl);
      }

      function updateDate() {
        $scope.event.date = $scope.eventUpdate.date;
        eventService.updateEventDate($scope.event.date, $scope.editUrl);
      }

      function updateDescription() {
        $scope.event.description = $scope.eventUpdate.description;
        eventService.updateEventDescription($scope.event.description, $scope.editUrl).then(function (result) {

        }, function (err) {
          console.log(err)
        });
      }

      function dateFormatter(date) {
        var tempDate = date;
        var tempDay;
        var tempMonth;
        var tempYear;
        var formattedDate;

        tempDay = tempDate.getDate();
        tempMonth = tempDate.getMonth() + 1;
        tempYear = tempDate.getFullYear();
        formattedDate = tempYear + '/' + tempMonth + '/' + tempDay;

        return formattedDate;
      }

      function deleteEvent() {
        eventService.deleteEvent($routeParams.editID);
        $location.url('/');
      }

      function kill() {
        $scope.event = {};

        if (timer) {
          $timeout.cancel(timer);
        }
      }

      init();

    }])
  ;


