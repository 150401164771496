angular.module('munchableApp')
  .controller('participantCreator.slide3.controller', [
    '$scope',
    'participantService',
    'tagsService',
    '$timeout',

    function ($scope, participantService, tagsService, $timeout) {
      var timer;

      $scope.currentParticipant = participantService.participant;
      $scope.sortedTags = {};

      $scope.checkTag = checkTag;
      $scope.addTagToParticipant = addTagToParticipant;


      $scope.showLetter = 'A';
      $scope.changeLetter = changeLetter;

      $scope.$on('$destroy', kill);


      function init() {
        tagsService.getAllTags().then(function (res) {
          $scope.allTags = res;
          sortTags();
        });
      }

      function changeLetter(letter) {
        $scope.showLetter = letter;
      }

      function sortTags() {
        var tmp = {},
          i = 0,
          l = $scope.allTags.length
          ;

        for (i; i < l; i++) {
          var letter = $scope.allTags[i].label.charAt(0);

          if (tmp[letter] == undefined) {
            tmp[letter] = []
          }
          tmp[letter].push($scope.allTags[i]);
        }

        $scope.sortedTags = tmp;
      }

      function addTagToParticipant(tag) {
        participantService.addTagToParticipant(tag);
        tag.checked = true;
      }

      function checkTag(tag) {
        if (!tag.checked) {
          participantService.addTagToParticipant(tag);
          tag.checked = true;
        } else {
          participantService.removeTagFromParticipant(tag);
          tag.checked = false;
        }

        $scope.filterText = null;
      }

      function kill() {
        $scope.allTags = null;
        if (timer) {
          $timeout.cancel(timer);

        }
        timer = null;
      }

      init();
    }])
  ;


