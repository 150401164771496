/*
TYPE: directive
NAME: tagCounter
USAGE: Use to display tagLabel + the number of appearance in the current event participants
ATTRIBUTES: label -> for the tag label
            counter -> counts the appearance of the label
*/

angular
  .module('munchableApp')
  .directive('tagCounter', [
    function () {
      return {
        scope: {
          label: '@',
          counter: '@'
        },
        restrict: 'E',
        replace: true,
        template: require('./tagCounter.tpl.html')
      }
    }
  ]);

