angular.module('munchableApp')
.controller('eventCreator.slide2.controller', [
    '$scope',
    '$timeout',
    'createEventService',

    function($scope, $timeout, createEventService) {
        $scope.newEvent = createEventService.getEvent();
        $scope.newEvent.date = null;

        $scope.minDate = dateFormatter(new Date());

        $scope.newEventCreated = false;
        $scope.errorType = null;
        $scope.error = null;

        $scope.showDatePopUp = false;
        $scope.clearDate = clearDate;

        $scope.$on('slideAllowed', closeDatePopUp);

        $scope.openDatePopUp = openDatePopUp;
        $scope.closeDatePopUp = closeDatePopUp;

        $scope.setBlockSlide = $scope.$parent.setBlockSlide;
        $scope.setAllowSlide = $scope.$parent.setAllowSlide;

        function closeDatePopUp() {
            $scope.showDatePopUp = false;
            $scope.setAllowSlide();
        }

        function openDatePopUp() {
            $scope.showDatePopUp = true;
            $scope.setBlockSlide(null, true);
        }

        function dateFormatter (date) {
            var tempDate = date;
            var tempDay;
            var tempMonth;
            var tempYear;
            var formattedDate;


            tempDay = tempDate.getDate();
            tempMonth = tempDate.getMonth() + 1;
            tempYear = tempDate.getFullYear();
            formattedDate = tempYear + '/' + tempMonth +  '/' + tempDay;


            return formattedDate;
        }

        function clearDate() {
            $scope.newEvent.date = null;
        }
}]);
