angular
  .module('munchableApp')
  .factory('eventService', [
    '$http',
    'api',
    function ($http, api) {
      var url = api, event = {};

      function deleteEvent(editUrl) {
        return $http.delete(url + 'events/' + editUrl);
      }

      function loadEventById(eventId) {
        if (event[eventId]) {
          return event[eventId]; // found in cache
        }
        var request = $http.get(url + 'events/' + eventId);

        request.then(function (res) {
          event[eventId] = res; // remember in cache
        });

        return request;
      }

      function updateEventTitle(newTitle, editUrl) {
        var currObject = {};
        currObject.title = newTitle;

        return $http.put(url + 'events/' + editUrl, currObject);
      }

      function updateEventDate(newDate, editUrl) {
        var currObject = {};
        currObject.date = newDate;

        return $http.put(url + 'events/' + editUrl, currObject);
      }

      function updateEventDescription(newDescr, editUrl) {
        var currObject = {};
        currObject.description = newDescr;

        return $http.put(url + 'events/' + editUrl, currObject);
      }

      function updateEventParticipantslist(participantList, editUrl) {
        var currObject = {};

        currObject.participants = participantList;

        for (var i = 0; i < currObject.participants.length; i++) {
          if (currObject.participants[i]._id) {
            delete currObject.participants[i]._id;
          }
        }

        return $http.put(url + 'events/' + editUrl, currObject);
      }

      return {
        loadEventById: loadEventById,
        updateEventTitle: updateEventTitle,
        updateEventDate: updateEventDate,
        updateEventDescription: updateEventDescription,
        updateEventParticipantslist: updateEventParticipantslist,
        deleteEvent: deleteEvent
      };
    }
  ]);
