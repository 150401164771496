angular
    .module('munchableApp')
    .factory('setsService', function($q, $http, $filter) {
        var setsCollection = null;

        /**
         * get all sets
         *
         * *
         * @returns {Object} -> returns a promise which will be resolved with sets or rejected with a custom error message
         */
        function getAllSets() {
            var response = $q.defer();

            if(angular.isArray(setsCollection)) {
                response.resolve(response.data.sets);
            } else {
                $http.get('./data/setsCollection.json').then(function(res) {
                    response.resolve(res.data.sets);
                    setsCollection = res.data.sets;
                }, function() {
                    response.reject('Not found!');
                });
            }

            return response.promise;
        }

        function getSetByCategory(cat) {
            var response = $q.defer();

            if(!cat) {
                response.reject('No category provided!');
            }

            if(setsCollection) {
                response.resolve(filterSetsByCat(cat));
            } else {
                getAllSets().then(function() {
                    response.resolve(filterSetsByCat(cat));
                }, function() {
                    response.reject('Not found!');
                })
            }

            return response.promise
        }

        //HELPER
        function filterSetsByCat(cat) {
            return $filter('filter')(setsCollection, {category:cat});
        }

        return {
            getSetByCategory: getSetByCategory,
            getAllSets: getAllSets
        };
});
