angular
  .module('munchableApp')
  .directive('trackingNote', [
    function() {
      function controller ($scope, $timeout, analyticsService) {
        var timer = null;
  
        $scope.requiredTracking = true;

        $scope.confirmTracking = function confirmTracking() {
          analyticsService.setTracking(true);
        };

        $scope.discardTracking = function discardTracking() {
          analyticsService.setTracking(false);
        };

        $scope.$on('$destroy', kill);

        function init() {
          $scope.isActive = localStorage.getItem('tracking-note-read') ? false : true;
        }

        function kill() {
          $scope.event = {};
  
          if (timer) {
            $timeout.cancel(timer);
            }
          }

          $timeout(init, 1000);
        }

        return {
            restrict: 'AE',
            controller,
            template: require('./trackingNote.tpl.html')
        }
    }
]);

