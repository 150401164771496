'use strict';

import angular from 'angular';
import './constants';
import 'angular-route';
import 'angular-animate';
import 'angular-touch';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-clipboard'

var munchableApp = angular.module('munchableApp', [
  'munchableApp.constants',
  'ngRoute',
  'ngAnimate',
  'ngTouch',
  'pascalprecht.translate',
  'angular-clipboard'
]);

munchableApp
  .config([
    '$routeProvider',
    '$translateProvider',
    function ($routeProvider, $translateProvider) {
      $routeProvider
        // route for the create page
        .when('/', {
          template: require('./create/create.tpl.html'),
          controller: 'create.controller'
        })

        // route for the individual event page
        .when('/event/:eventID', {
          template: require('./event/event.tpl.html'),
          controller: 'event.controller'
        })

        // route for the individual event page
        .when('/event/:eventID/:editID', {
          template: require('./admin/admin.tpl.html'),
          controller: 'admin.controller'
        })

        .otherwise({
          redirectTo: '/'
        });

      $translateProvider.useStaticFilesLoader({
        prefix: 'i18n/',
        suffix: '.json'
      });

      $translateProvider.preferredLanguage('de_DE');

    }])
  .run(function (stateRegistry) {
    stateRegistry.setStatus('initialized');
  })
  .controller('main.controller',
    function ($scope, $timeout, stateRegistry) {
      stateRegistry.registerToStatus('initialized', function (key, value) {
        if (value === 'set') {
          //call it with a little delay to have prevent splashscreen flickr
          $timeout(function () {
            $scope.initialized = true;
          }, 300);
        } else {
          $scope.initialized = false;
        }
      });
    }
  );

export default munchableApp
