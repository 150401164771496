import { header, slides } from './eventCreatorSlides';

angular
  .module('munchableApp')
  .controller('eventCreator.controller', [
    '$scope',
    'analyticsService',
    function ($scope, analyticsService) {
      $scope.currentSlides = slides;
      $scope.currentHeader = header;

      analyticsService.trackEvent(
        slides[0].tracking.event,
        slides[0].tracking.label
      );
    }
  ]);
