/*
TYPE: directive
NAME: setCollection

USAGE: Use inside element <pop-up> / closely tied.

ATTRS:
 - active: boolean when popup is open -> create current tags
*/
angular
  .module('munchableApp')
  .directive('setCollection', [
    'participantService',
    'tagsService',
    function (participantService, tagsService) {
      function link(scope, element, attrs, controller) {
        scope.createCurrentTags = createCurrentTags;

        //prototypical inheritance: this will called when
        //confirm button is clicked
        controller.childrenConfirmClick = addToParticipant;

        scope.$watch('active', function () {
          if (scope.active) {
            createCurrentTags();
          }
        });

        function createCurrentTags() {
          var l = scope.set.tags.length;
          scope.currSet = [];

          for (var i = 0; i < l; i++) {
            var currTag = {};

            currTag.label = scope.set.tags[i].label;
            currTag.id = scope.set.tags[i].id;
            currTag.checked = true;

            scope.currSet.push(currTag);
          }
        }

        function addToParticipant() {
          var l = scope.currSet.length;

          for (var i = 0; i < l; i++) {
            if (scope.currSet[i].checked) {
              participantService.addTagToParticipant(scope.currSet[i]);
              tagsService.checkTag(scope.currSet[i].id);
            }
          }
          participantService.addSetToParticipant(scope.set);
        }
      }

      return {
        scope: {
          set: '=',
          active: '='
        },
        restrict: 'AE',
        link,
        require: '^popUp',
        template: require('./setCollection.tpl.html')
      }
    }
  ]);
