angular.module('munchableApp')
.controller('event.controller', [
    '$scope',
    '$routeParams',
    'eventService',
    '$timeout',

    function($scope, $routeParams, eventService, $timeout) {
        var timer = null;

        $scope.event = {};
        $scope.error = null;
        $scope.errorType = null;
        $scope.showParticipantCreator = false;
        $scope.firstStep = true;
        $scope.contentLoading = false;


        $scope.showSecondStep = showSecondStep;

        //bool to toggle visibility of description box
        $scope.showDescription = false;

        $scope.$on('$destroy', kill);

        //initially load event depending on id of state param // if param is provided through URL
        function init() {
            if ($routeParams.eventID !== undefined) {
                eventService.loadEventById($routeParams.eventID).then(function(result) {
                    $scope.event = result.data;
                    $scope.error = false;

                    $scope.event.date = Date.parse($scope.event.date);
                }, function(err) {
                    $scope.errorType = err;
                    $scope.error = true;
                });
            }
        }

        function showSecondStep() {
            $scope.firstStep = false;
            $scope.contentLoading  = true;

            //timeout as long as transition takes to guarantee a proper animation feeling
            timer = $timeout(function() {
                $scope.showParticipantCreator = true;
                $scope.contentLoading = false;
            }, 700);

        }

        function kill() {
            if (timer) {
                $timeout.cancel(timer);
            }
        }

        init();
    }
  ])
;


