angular
  .module('munchableApp')
  .factory('tagsService', function ($q, $http, $filter) {
    var tagsCollection = null;

    /**
     * get a tag by it�s id. If there�s no Id given, function returns null immediately
     *
     * @param tagId
     * @returns {deferred.promise|{then, catch, finally}|Document.promise|k.promise|*|s.promise} if tagId is given
     */
    function getTagById(tagId) {
      if (!tagId) {
        return null;
      }

      var response = $q.defer();

      if (tagsCollection) {
        response.resolve(filterTagById(tagId));
      } else {
        getAllTags().then(function () {
          response.resolve(filterTagById(tagId));
        }, function () {
          response.reject(`Tag with id ${tagId} not found!`);
        })
      }

      return response.promise;
    }

    /**
     * get all tags
     *
     * *
     * @returns {*}
     */
    function getAllTags() {
      var response = $q.defer();

      if (angular.isArray(tagsCollection)) {
        response.resolve(tagsCollection);
      } else {
        $http.get('./data/tagsCollection.json').then(function (res) {
          response.resolve(res.data.tags);
          tagsCollection = res.data.tags;
        }, function () {
          response.reject('Not found!');
        });

      }

      return response.promise;

    }

    /**
     * mark tag checked
     *
     * @to-do vereinheitlichen das man nicht mehr die id als param geben muss
     *
     * @param tagId
     */
    function checkTag(tagId) {
      if (!tagId) {
        return null;
      }

      var result;

      if (tagsCollection) {
        result = $filter('filter')(tagsCollection, { id: tagId })[0];
        result.checked = true;
      } else {
        getAllTags().then(function () {
          result = $filter('filter')(tagsCollection, { id: tagId })[0];
          result.checked = true;
        })
      }
    }

    /**
     * mark a tag as unchecked
     *
     * @param tagId
     * @returns {null}
     */
    function uncheckTag(tagId) {
      if (!tagId) {
        return null;
      }

      var result;

      if (tagsCollection) {
        result = $filter('filter')(tagsCollection, { id: tagId })[0];
        result.checked = false;
      } else {
        getAllTags().then(function () {
          result = $filter('filter')(tagsCollection, { id: tagId })[0];
          result.checked = false;
        })
      }
    }

    /**
     * mark all tags as unchecked
     *
     */
    function uncheckAllTags() {
      if (tagsCollection) {
        for (var i = 0; i < tagsCollection.length; i++) {
          tagsCollection[i].checked = false;
        }
      } else {
        getAllTags().then(function () {
          for (var i = 0; i < tagsCollection.length; i++) {
            tagsCollection[i].checked = false;
          }
        })
      }
    }

    //HELPER
    function filterTagById(tagId) {
      return $filter('filter')(tagsCollection, { id: tagId })[0];
    }

    return {
      getAllTags: getAllTags,
      getTagById: getTagById,
      checkTag: checkTag,
      uncheckTag: uncheckTag,
      uncheckAllTags: uncheckAllTags
    };
  });
