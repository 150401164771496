// eslint-disable-next-line
const API_URL = ENV_PRODUCTION
  ? 'https://munchie.uber.space/api/'
  : 'http://localhost:8003/'

const HOST_URL = ENV_PRODUCTION
  ? 'https://app.munchable.co/'
  : 'http://localhost:3000'

angular
  .module('munchableApp.constants', [])
  .constant('api', API_URL)
  .constant('host', HOST_URL);
