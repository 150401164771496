angular
  .module('munchableApp')
  .controller('participantCreator.slide2.controller', [
    '$scope',
    'tagsService',
    'setsService',
    'participantService',
    '$filter',
    '$timeout',

    function ($scope, tagsService, setsService, participantService, $filter, $timeout) {
      var timer;

      $scope.setsCollection = [];
      $scope.tags = [];

      $scope.addSet = addSet;

      $scope.currTags = [];
      $scope.currSet = null;

      $scope.popupOpen = false;

      $scope.showSets = true;

      $scope.setStepsHeight = $scope.$parent.setStepsHeight;
      $scope.slideTo = $scope.$parent.slideTo;

      $scope.addToParticipant = addToParticipant;

      $scope.switchCallback = switchCallback;
      $scope.$on('$destroy', kill);


      function init() {
        //initially load all tags for the view
        setsService.getSetByCategory('allergy').then(function (result) {
          $scope.setsCollection = result;
          tagsService.getAllTags().then(function (res) {
            $scope.tags = res;
            fillSetsWithTags();
          });

        }, function (err) {
          $scope.error = err;
        });
      }

      function switchCallback() {
        timer = $timeout(function () {
          $scope.setStepsHeight();
        }, 0);
      }

      function fillSetsWithTags() {
        var l = $scope.setsCollection.length;
        for (var i = 0; i < l; i++) {
          var tagL = $scope.setsCollection[i].tags.length;

          for (var j = 0; j < tagL; j++) {
            (function (i, j) {
              var currObj = {},
                currId = $scope.setsCollection[i].tags[j];

              tagsService.getTagById(currId).then(function (result) {
                currObj = result;
                $scope.setsCollection[i].tags[j] = currObj;
              }, function (err) {
                $scope.error = err;
              });
            })(i, j);
          }
        }
      }

      function addSet(setId) {
        $scope.currSet = $filter('filter')($scope.setsCollection, { id: setId })[0];
        $scope.popupOpen = true;
      }

      function addToParticipant() {
        var l = $scope.currSet.tags.length;

        for (var i = 0; i < l; i++) {
          if ($scope.currSet.tags[i].checked) {
            participantService.addTagToParticipant($scope.currSet.tags[i]);
          }
        }

        participantService.addSetToParticipant($scope.currSet);
      }

      function kill() {
        if (timer) {
          $timeout.cancel(timer);
        }
      }

      init();

    }
  ]);
