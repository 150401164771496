angular
  .module('munchableApp')
  .directive('stopMaxLength',
    function () {
      function link(scope, element, attrs) {
        var max = parseInt(attrs.myMaxlength) || 10;

        scope.$watch('model', function (newVal, oldVal) {
          if (scope.model !== undefined && scope.model.length >= max) {
            scope.model = oldVal;
          }
        });
      }

      return {
        require: 'ngModel',
        restrict: 'A',
        link,
        scope: {
          model: '=ngModel'
        }
      }

    }
  );

